// ** Utils

import congesService from '@src/data/congesService';
import { useTranslation } from 'react-i18next';

// ** Request to process filters

const LeavesConfig = (
  setFilter,
  setIsFilterModalOpen,
  selectedOptions,
  setSelectedOptions
) => {
  const { t } = useTranslation();

  // action after saving filter
  const handleSubmit = () => {
    buildFilter(selectedOptions);
    setIsFilterModalOpen(false);
  };

  // action after cancel filter
  const handleClose = () => {
    setIsFilterModalOpen(false);
  };

  // build the filter
  const buildFilter = (options) => {
    let filterToSend = [];
    Object.keys(options).forEach((key) => {
      if (options[key] && options[key].length > 0) {
        if (Array.isArray(options[key])) {
          if (key === 'period') {
            filterToSend.push({
              field: 'start_date',
              operator: '>=',
              value: options[key][0],
            });
            filterToSend.push({
              field: 'end_date',
              operator: '<=',
              value: options[key][1],
            });
          } else {
            filterToSend.push({
              field: key,
              operator: 'in',
              value: options[key].map((item) => item.id),
            });
          }
        } else {
          filterToSend.push({
            field: key,
            operator: 'like',
            value: `%${options[key]}%`,
          });
        }
      }
    });
    setFilter(filterToSend);
  };

  // return leave types list
  const loadLeaveTypes = async () => {
    const response = await congesService.get(`/v1/leave-types`);
    return {
      options: response.data.data.map((res) => {
        return {
          ...res,
          name: `${res.name}`,
        };
      }),
      hasMore: false,
    };
  };

  // return status list
  const loadStatus = async () => {
    const response = await congesService.get(`/v1/statuses`);
    return {
      options: response.data.data.map((res) => {
        return {
          ...res,
          tag: `${res.tag}`,
        };
      }),
      hasMore: false,
    };
  };

  // reset filters
  const handleReset = () => {
    setSelectedOptions({});
    setFilter([]);
    getData(1, []);
  };

  // filters fields
  const fields = [
    {
      name: 'leave_type',
      label: t('leaveTypes'),
      type: 'asyncPaginate',
      value: selectedOptions['leave_type.id'] ?? [],
      placeholder: t('chooseLeaveTypes'),
      optionLabel: 'name',
      optionValue: 'leave_type',
      isMulti: true,
      loadOptions: loadLeaveTypes,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          'leave_type.id': e,
        });
      },
    },
    {
      name: 'period',
      label: t('period'),
      type: 'date',
      value: selectedOptions['period'] ?? [],
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          period: e,
        });
      },
    },
    {
      name: 'status',
      label: t('status'),
      type: 'asyncPaginate',
      value: selectedOptions['status.id'] ?? [],
      placeholder: t('submittedToValidation'),
      optionLabel: 'tag',
      optionValue: 'status',
      isMulti: true,
      loadOptions: loadStatus,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          'status.id': e,
        });
      },
    },
  ];

  return {
    title: t('Filters'),
    fields,
    handleSubmit: handleSubmit,
    handleClose: handleClose,
    handleReset: handleReset,
  };
};

export default LeavesConfig;
