// ** Default import

import React, { useState, useEffect, useContext } from 'react';
import congesService from '@data/congesService';
import { useTranslation } from 'react-i18next';

// ** Utils

import UserContext from '@context/UserContext';

import useStatus from '@hooks/useStatus';
import FilterData from '@helpers/FilterData';
import { capitalizeFirstLetter } from '@src/utility/Utils';
import LeavesConfig from '@helpers/FiltersConfigs/LeavesConfig';

// ** FontAwesomeIcon

// ** UI

import Spinner from '@components/UI/Spinner';
import Badge from '@components/UI/Badge';
import ToastCustom from '@components/UI/ToastCustom';
import DataGridCustom from '@components/UI/DataGridCustom';
import { FilterButton } from '@components/UI/Buttons';
import { ButtonSecondAction } from '@components/UI/Buttons';
import LeaveResume from '@helpers/LeaveResume';
import { formatDate } from '@utility/Utils';

import useSortables from '@hooks/useSortables';
import LeavesWarnings from '@components/UI/NoJustifyTooltip';

const Leaves = () => {
  const { t } = useTranslation();

  const [leaves, setLeaves] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [currentLeave, setCurrentLeave] = useState({});

  const [metadata, setMetadata] = useState({
    current_page: 1,
    last_page: 1,
    total: 1,
    options: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filters, setFilters] = useState([]);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [sort, setSort] = useState([
    {
      field: 'start_date',
      direction: 'desc',
    },
  ]);

  const [selectedOptions, setSelectedOptions] = useState({});

  const getStatusByTag = useStatus();

  const { currentUser } = useContext(UserContext);

  const { handleSortModelChange } = useSortables(sort, setSort);

  const isLeaveNeedToBeJustified = (leave) => {
    if (leave.leave_type.can_justify_later && !leave.attachment_name) {
      if (
        leave.status.tag === 'SUBMITTED' ||
        leave.status.tag === 'VALIDATED'
      ) {
        return true;
      }
    }
  };

  const fetchLeaves = async (page) => {
    await congesService
      .post(
        `/v1/leaves/search?include=status,user,user.validators,leave_type,histories,histories.status,histories.user,leave_type_sub_family&limit=10&page=${
          page ? page : metadata.current_page
        }`,
        {
          filters: [
            {
              field: 'user_id',
              operator: '=',
              value: currentUser.id,
            },
          ].concat(filters),
          sort: sort,
        }
      )
      .then((resp) => {
        const getOptions = () => {
          const option = [];
          for (let i = 1; i <= resp.data.meta.last_page; i++) {
            option.push({
              value: i,
              label: i,
            });
          }
          return option;
        };

        setLeaves(
          resp.data.data.map((leave) => ({
            ...leave,
            should_justify_later: isLeaveNeedToBeJustified(leave),
          }))
        );

        setMetadata((prevState) => ({
          ...prevState,
          current_page: resp.data.meta.current_page,
          last_page: resp.data.meta.last_page,
          per_page: resp.data.meta.per_page,
          total: resp.data.meta.total,
          options: getOptions() ?? [],
        }));
      })
      .catch((error) => {
        console.error(error);
        ToastCustom.error(t(error.response?.data?.details));
      })
      .finally(() => setIsLoading(false));
  };

  // action after clicking on a row
  const handleRowClick = (event) => {
    setCurrentLeave(event.row);
    setIsModalOpen(true);
  };

  // action after changing page
  const onPageChange = (page) => {
    setMetadata((prevState) => ({
      ...prevState,
      current_page: page + 1,
    }));
  };

  const initData = async () => {
    await fetchLeaves(1, []);
  };

  useEffect(() => {
    initData().finally(() => setIsLoading(false));
  }, [filters, sort]);

  const columns = [
    {
      field: 'leave_type',
      headerName: t('leaveType'),
      flex: 1,
      minWidth: 220,
      renderCell: (value) => value.row.leave_type.name,
    },
    {
      field: 'period',
      headerName: t('period'),
      minWidth: 300,
      flex: 2,
      renderCell: (value) => {
        return formatDate(value.row.start_date, value.row.end_date, true);
      },
    },
    {
      field: 'duration',
      headerName: capitalizeFirstLetter(t('days')),
      headerAlign: 'center',
      align: 'center',
      width: 100,
    },
    {
      field: 'status',
      headerName: t('status'),
      headerAlign: 'center',
      minWidth: 150,
      flex: 1.5,
      align: 'center',
      renderCell: (params) => (
        <div className='w-44'>
          <Badge
            value={getStatusByTag(params.value.tag)}
            color={params.value.color}
            className='flex items-center justify-center'
          />
        </div>
      ),
    },
    {
      field: 'can_justify_later',
      headerName: '',
      width: 60,
      sortable: false,
      align: 'center',
      renderCell: ({ row }) => <LeavesWarnings row={row} />,
    },
  ];

  return (
    <>
      <h1 className={'mb-6 text-2xl'}>{t('myLeaves')}</h1>
      <div className='bg-white p-7 rounded-md'>
        <div className='mb-4'>
          <div className='flex justify-end align-center gap-x-2 mb-8'>
            <ButtonSecondAction
              type='button'
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
            >
              {t('filters')}
            </ButtonSecondAction>

            <FilterButton
              selectedOptions={filters}
              onClick={() => {
                setFilters([]);
                setSelectedOptions({});
              }}
            />
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <DataGridCustom
              columns={columns}
              rows={leaves}
              onSortModelChange={handleSortModelChange}
              getData={fetchLeaves}
              metadata={metadata}
              setMetaData={setMetadata}
              handleRowClick={handleRowClick}
              onPageChange={onPageChange}
            />
          )}
          {isModalOpen && (
            <LeaveResume
              currentLeave={currentLeave}
              getData={fetchLeaves}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              getStatusByTag={getStatusByTag}
              needCancel
            />
          )}
          <FilterData
            manager={() => {
              return LeavesConfig(
                setFilters,
                setIsFilterModalOpen,
                selectedOptions,
                setSelectedOptions
              );
            }}
            isOpen={isFilterModalOpen}
            setIsOpen={setIsFilterModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default Leaves;
