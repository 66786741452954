import React from 'react';
import NoAttachment from '@assets/svg/noAttachment.inline.svg';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoJustifyTooltip = React.forwardRef(function MyComponent(props, ref) {
  return (
    <div {...props} ref={ref} className='h-6 w-6 flex items-center'>
      <NoAttachment />
    </div>
  );
});

const LeavesWarnings = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div className='flex gap-x-2'>
      {row.should_justify_later ? (
        <Tooltip title={t('noJustificationTooltip')} placement='bottom' arrow>
          <NoJustifyTooltip />
        </Tooltip>
      ) : (
        ''
      )}
      {row.out_day !== 0 ? (
        <Tooltip
          title={
            t('leaveOutDay') + ' ' + Math.abs(row.out_day) + ' ' + t('days')
          }
          placement='bottom'
          arrow
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className='h-6 w-5 text-orange-500'
          />
        </Tooltip>
      ) : (
        ''
      )}
    </div>
  );
};

export default LeavesWarnings;
