import { useCallback } from 'react';

const useSortables = (sort, setSort, relations = 'leave') => {
  const handleSortModelChange = useCallback((sortModel) => {
    const finalSort = [];
    //format leavetype in leave_type_name
    if (sortModel.length) {
      sortModel.forEach((sort) => {
        switch (sort.field) {
          case 'leave_type':
            finalSort.push({
              field: 'leave_type.name',
              direction: sort.sort,
            });
            break;
          case 'status':
            finalSort.push({
              field: 'status.name',
              direction: sort.sort,
            });
            break;
          case 'period':
          case 'date':
            if (relations === 'user') {
              finalSort.push({
                field: 'enter_date',
                direction: sort.sort,
              });
            } else {
              finalSort.push({
                field: 'start_date',
                direction: sort.sort,
              });
            }

            break;
          case 'validation':
            finalSort.push({
              field: 'updated_at',
              direction: sort.sort,
            });
            break;

          case 'matricule':
            if (relations === 'user') {
              finalSort.push({
                field: 'matricule',
                direction: sort.sort,
              });
            } else {
              finalSort.push({
                field: 'user.matricule',
                direction: sort.sort,
              });
            }

            break;
          case 'teams':
            finalSort.push({
              field: 'tags.label',
              direction: sort.sort,
            });
            break;
          case 'user':
            if (relations === 'user') {
              finalSort.push({
                field: 'lastname',
                direction: sort.sort,
              });
            } else {
              finalSort.push({
                field: 'user.lastname',
                direction: sort.sort,
              });
            }
            break;
          case 'site':
            finalSort.push({
              field: 'sortSiteName',
              direction: sort.sort,
            });
            break;
          case 'name':
            if (relations === 'user') {
              finalSort.push({
                field: 'lastname',
                direction: sort.sort,
              });
            }
            break;
          default:
            finalSort.push({
              field: sort.field,
              direction: sort.sort,
            });
            break;
        }
      });
    }
    setSort(finalSort);
  }, []);

  return {
    handleSortModelChange,
    sort,
  };
};

export default useSortables;
